<template>
  <v-container fluid pa-0>
    <div v-if="projectData" class="mt-4 empty-state">
      <v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <ProjectMain
        :is-project-empty="isProjectEmpty"
        @projectAdded="projectAdded"
      />
    </div>
  </v-container>
</template>

<script>
import ProjectMain from "@/components/workflow/project/ProjectMain";
import UserDataMixin from "@/mixins/UserData";
import { bus } from "@/main";
export default {
  name: "Project",
  mixins: [UserDataMixin],
  data() {
    return {
      projectData: true,
      isProjectEmpty: true,
    };
  },
  components: {
    ProjectMain,
  },
  async created() {
    this.isProjectEmpty = this.$store.state.Project.project.length
      ? false
      : true;
    bus.$on("projectFileUploaded", async (attachmentData) => {
      // update project attachment count
      if (attachmentData[0].reference_type === "project") {
        await this.updateProjectAttachmentCount(attachmentData);
      }
    });
    bus.$on("deleteProjectAttachments", async (attachmentData) => {
      if (attachmentData[0].reference_type === "project") {
        await this.updateProjectAttachmentCount(attachmentData);
      }
    });
    await this.getProjectData();
    bus.$on("projectUpdated", this.getProjectData);
  },
  computed: {
    usersCompany() {
      return this.$store.state.User.companyUserMap;
    },
  },
  watch: {},
  methods: {
    async getProjectData() {
      const projectDataResponse =
        await this.$store.dispatch("getCompanyProject");
      await this.usersCompany;
      if (projectDataResponse.status !== "error") {
        if (projectDataResponse && projectDataResponse.length === 0)
          this.isProjectEmpty = true;
        else this.isProjectEmpty = false;
        this.projectData = false;
        projectDataResponse.forEach((item) => {
          item.progress = item.no_of_task
            ? parseInt((item.no_of_completed_task / item.no_of_task) * 100)
            : 0;
          item.tasks = item.no_of_task ? item.no_of_task : 0;
          item.attachments = item.no_of_attachments;
          if (item.status === null) item.status = "Planning";
        });
        this.$store.commit("setProjectData", projectDataResponse);
      }
    },
    async projectAdded() {
      await this.getProjectData();
    },
    async updateProjectAttachmentCount(attachmentData) {
      const projects = this.$store.state.Project.project;
      const projectPayload = { project_id: attachmentData[0].reference_id };
      const projectData = await this.getProjectDataById(projectPayload);
      projects.forEach((item) => {
        if (item.project_id === attachmentData[0].reference_id) {
          item.no_of_attachments = projectData[0].no_of_attachments;
        }
      });
      this.$store.commit("setProjectData", [...projects]);
    },
    async getProjectDataById(projectData) {
      const projectDataResponse = await this.$store.dispatch(
        "getProjectDataById",
        projectData,
      );
      return projectDataResponse;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.empty-state {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
