var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { attrs: { fluid: "", "pa-0": "" } }, [
    _vm.projectData
      ? _c(
          "div",
          { staticClass: "mt-4 empty-state" },
          [
            _c("v-progress-circular", {
              attrs: { size: 70, width: 7, color: "green", indeterminate: "" },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("ProjectMain", {
              attrs: { "is-project-empty": _vm.isProjectEmpty },
              on: { projectAdded: _vm.projectAdded },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }