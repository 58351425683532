<template>
  <div>
    <v-row v-if="!isProjectDetails && !isTicketDetails">
      <v-col cols="12" class="pa-0">
        <v-alert
          v-if="isProjectExceeded"
          type="warning"
          color="#AD4C0B"
          icon="mdi-alert-outline"
          text
          class="alert-box caption font-weight-regular py-3 px-5"
          height="44px"
          dismissible
          style="width: 100%"
        >
          {{ $t("projectLimitExceeded") }}
          <a class="primary--text" @click="$router.push('/subscription')">
            {{ $t("upgradePlan") }}</a
          >
          {{ $t("toAddMoreProject") }}
        </v-alert>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        md="3"
        lg="4"
        class="text-h5 font-weight-medium"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
      >
        {{ $t("projects") }}
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-col class="py-1" v-if="!$vuetify.breakpoint.mdAndUp"
        ><v-divider></v-divider
      ></v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" md="3" lg="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          flat
          solo
          dense
          background-color="grey lighten-4"
          class="rounded-lg"
          clear-icon="mdi-close-circle"
          clearable
        >
        </v-text-field>
      </v-col>

      <div class="mr-3">
        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="ml-3">
          <v-btn
            v-if="checkPermission('createProject')"
            :disabled="isProjectExceeded"
            color="primary"
            class="font-weight-regular text-none rounded-lg"
            @click="onAddProjectClicked"
            ><v-icon>mdi-plus</v-icon>
            {{ isProjectEmpty ? $t("addProject") : $t("createProject") }}</v-btn
          >
        </v-col>
      </div>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <div v-if="isProjectEmpty" class="mt-4 empty-state">
      <v-card
        elevation="0"
        width="100%"
        :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
      >
        <v-img
          height="auto"
          src="@/assets/images/project-empy-state.svg"
          max-width="100"
          min-width="100"
          class="mx-auto my-2"
        >
        </v-img>
        <v-row>
          <span
            class="h6 font-weight-medium grey--text text--darken-4 mx-auto my-2"
          >
            {{ $t("noProjectsCreated") }}
          </span>
        </v-row>
        <v-row>
          <span
            class="subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center"
            style="display: block"
          >
            <template v-if="$vuetify.breakpoint.mdAndUp">
              Click the add project button to create your first project
            </template>
            <template v-else>
              Click the add project button to <br> create your first project
            </template>
          </span>
        </v-row>
      </v-card>
    </div>
    <ViewProject v-if="!isProjectEmpty" :search="search" />
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown && checkPermission('createProject')"
      v-model="bottomNavbar"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
    >
      <v-row class="px-4 py-2">
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular text-h6 white--text"
          block
          height="50"
          @click="onAddProjectClicked"
          >{{ isProjectEmpty ? $t("addProject") : $t("createProject")
          }}<v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-row>
    </v-bottom-navigation>
    <v-layout>
      <div
        v-if="$vuetify.breakpoint.mdAndUp && addProjectDialog"
        id="addProjectPanel"
      >
        <AddProject
          @projectAdded="projectAdded"
          @hideProjectDialog="hideProjectDialog"
        />
      </div>
      <v-dialog v-else v-model="addProjectDialog" fullscreen>
        <AddProject
          @projectAdded="projectAdded"
          @hideProjectDialog="hideProjectDialog"
        />
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import UserDataMixin from "@/mixins/UserData";
export default {
  name: "ProjectMain",
  mixins: [UserDataMixin],
  components: {
    AddProject: () => import("./AddProject.vue"),
    ViewProject: () => import("@/components/workflow/project/ViewProject"),
  },
  props: {
    isProjectEmpty: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isProjectDetails: false,
      bottomNavbar: true,
      addProjectDialog: false,
      search: "",
      projectDetails: {},
      isTicketDetails: false,
      TicketData: {},
      projectTab: "Tasks",
      ticketAlertMessage: "",
      ticketSuccessAlert: false,
      allSubscriptions: [],
      projectArray: [],
      maxProjectCount: 0,
    };
  },
  computed: {
    companyMaxProjectCount() {
      return parseInt(this.$store.state.Company.company.project_count, 10);
    },
    companyCurrentProjectCount() {
      let projectArray = this.$store.state.Project.project;
      let activeProject = projectArray.filter((item) => {
        return item.status !== "Inactive";
      });
      return activeProject.length;
    },
    isProjectExceeded() {
      return (
        this.companyCurrentProjectCount >= this.companyMaxProjectCount &&
        this.companyMaxProjectCount > -1
      );
    },
  },
  watch: {
    async companyInformation() {
      const CompanyData = await this.$store.dispatch("getPurchaseHistoryById", {
        companyId: this.companyInformation.company_id,
      });
    },
  },
  async created() {
    await this.fetchSubscriptiosData();
  },
  methods: {
    onAddProjectClicked() {
      this.addProjectDialog = true;
    },
    projectAdded() {
      this.addProjectDialog = false;
      this.$emit("projectAdded");
    },
    hideProjectDialog() {
      this.addProjectDialog = false;
    },
  },
};
</script>

<style lang="scss">
.empty-state {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#addProjectPanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  max-width: 560px;
  margin-right: 0px;
  margin-top: -60px;
  margin-left: 0px;
  margin-bottom: -20px;
  z-index: 100;
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 768px) {
  .alert-box {
    display: flex;
    align-items: center;
  }
}
</style>
