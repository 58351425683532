var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isProjectDetails && !_vm.isTicketDetails
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _vm.isProjectExceeded
                    ? _c(
                        "v-alert",
                        {
                          staticClass:
                            "alert-box caption font-weight-regular py-3 px-5",
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "warning",
                            color: "#AD4C0B",
                            icon: "mdi-alert-outline",
                            text: "",
                            height: "44px",
                            dismissible: "",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("projectLimitExceeded")) + " "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "primary--text",
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push("/subscription")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("upgradePlan")))]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("toAddMoreProject")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-h5 font-weight-medium",
                  class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8" : "pl-5",
                  attrs: {
                    cols: _vm.$vuetify.breakpoint.mdAndUp ? "4" : "12",
                    md: "3",
                    lg: "4",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("projects")) + " ")]
              ),
              _vm.$vuetify.breakpoint.mdAndUp ? _c("v-spacer") : _vm._e(),
              !_vm.$vuetify.breakpoint.mdAndUp
                ? _c("v-col", { staticClass: "py-1" }, [_c("v-divider")], 1)
                : _vm._e(),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: _vm.$vuetify.breakpoint.mdAndUp ? "4" : "12",
                    md: "3",
                    lg: "4",
                  },
                },
                [
                  _c("v-text-field", {
                    staticClass: "rounded-lg",
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      label: _vm.$t("search"),
                      "single-line": "",
                      "hide-details": "",
                      flat: "",
                      solo: "",
                      dense: "",
                      "background-color": "grey lighten-4",
                      "clear-icon": "mdi-close-circle",
                      clearable: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mr-3" },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-col",
                        { staticClass: "ml-3" },
                        [
                          _vm.checkPermission("createProject")
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-regular text-none rounded-lg",
                                  attrs: {
                                    disabled: _vm.isProjectExceeded,
                                    color: "primary",
                                  },
                                  on: { click: _vm.onAddProjectClicked },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isProjectEmpty
                                          ? _vm.$t("addProject")
                                          : _vm.$t("createProject")
                                      )
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-row", [_c("v-divider")], 1),
      _vm.isProjectEmpty
        ? _c(
            "div",
            { staticClass: "mt-4 empty-state" },
            [
              _c(
                "v-card",
                {
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
                  attrs: { elevation: "0", width: "100%" },
                },
                [
                  _c("v-img", {
                    staticClass: "mx-auto my-2",
                    attrs: {
                      height: "auto",
                      src: require("@/assets/images/project-empy-state.svg"),
                      "max-width": "100",
                      "min-width": "100",
                    },
                  }),
                  _c("v-row", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "h6 font-weight-medium grey--text text--darken-4 mx-auto my-2",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("noProjectsCreated")) + " ")]
                    ),
                  ]),
                  _c("v-row", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center",
                        staticStyle: { display: "block" },
                      },
                      [
                        _vm.$vuetify.breakpoint.mdAndUp
                          ? [
                              _vm._v(
                                " Click the add project button to create your first project "
                              ),
                            ]
                          : [
                              _vm._v(" Click the add project button to "),
                              _c("br"),
                              _vm._v(" create your first project "),
                            ],
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isProjectEmpty
        ? _c("ViewProject", { attrs: { search: _vm.search } })
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown && _vm.checkPermission("createProject")
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
              model: {
                value: _vm.bottomNavbar,
                callback: function ($$v) {
                  _vm.bottomNavbar = $$v
                },
                expression: "bottomNavbar",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "px-4 py-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-none rounded-lg font-weight-regular text-h6 white--text",
                      attrs: { color: "primary", block: "", height: "50" },
                      on: { click: _vm.onAddProjectClicked },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.isProjectEmpty
                            ? _vm.$t("addProject")
                            : _vm.$t("createProject")
                        )
                      ),
                      _c("v-icon", [_vm._v("mdi-plus")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        [
          _vm.$vuetify.breakpoint.mdAndUp && _vm.addProjectDialog
            ? _c(
                "div",
                { attrs: { id: "addProjectPanel" } },
                [
                  _c("AddProject", {
                    on: {
                      projectAdded: _vm.projectAdded,
                      hideProjectDialog: _vm.hideProjectDialog,
                    },
                  }),
                ],
                1
              )
            : _c(
                "v-dialog",
                {
                  attrs: { fullscreen: "" },
                  model: {
                    value: _vm.addProjectDialog,
                    callback: function ($$v) {
                      _vm.addProjectDialog = $$v
                    },
                    expression: "addProjectDialog",
                  },
                },
                [
                  _c("AddProject", {
                    on: {
                      projectAdded: _vm.projectAdded,
                      hideProjectDialog: _vm.hideProjectDialog,
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }